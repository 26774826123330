<script>
export default {
  el: "#appFooter",
  data: {
    name: "",
  },
  name: "footer",
  mounted() {},
};
</script>

<style lang="less">
#appFooter {
  min-height: 320px;
  background-color: #2b2b2b;
  text-align: left;
  .bottom {
    max-width: 1200px;
    margin: auto;
    padding: 42px 0;
    padding-top: 48px;
    color: rgba(255, 255, 255, 0.5);
    .logtitle {
      height: 58px;
      margin-right: 34px;
      padding-right: 34px;
      border-right: 1px #5f5f5f solid;
    }
    .contact {
      margin-top: 65px;
      .ricon {
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-right: 11px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        text-align: center;
        line-height: 56px;
        font-size: 0;
        img {
          line-height: 0;
          vertical-align: middle;
        }
      }
      h3 {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .bottomMenu {
      height: 58px;
      padding-top: 38px;
      line-height: 1;
      a {
        display: inline-block;
        margin-right: 40px;
        color: #ffffff;
        font-size: 18px;
        &:hover {
          color: #cccccc;
        }
      }
    }
    .bottomQr {
      color: #cccccc;
      text-align: center;
      .qr {
        display: inline-block;
        margin: 15px;
        margin-bottom: 17px;
        .qrimg {
          padding: 3px;
          background-color: #ffffff;
        }
      }
    }
  }
  footer {
    text-align: center;
    line-height: 1.8;
    background: #000000;
    color: #999999;
    padding: 20px;
    font-size: 12px;
    a {
      color: #999999;
      &:hover {
        color: #aaaaaa;
      }
    }
  }
}
</style>
