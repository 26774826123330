import Topbar from './topbar'
import Footer from './footer'/*  */
Topbar["store"] = A.store;
Footer["store"] = A.store;
if (document.querySelector("#topbar")) {
    const topbar = new vwx.Vue(Topbar);
}
const footer = new vwx.Vue(Footer);
if (window.A) {
    A.catchUaction.init();
    if (A.db.user.userId && A.db.token) {
        A.userInfo()
        A.catchUaction.upInfo({
            user_id: A.db.user.userId
        })
    }
    A.store.commit('user/setToken', A.db.token)
    A.store.commit('user/setInfo', A.db.user)
    A.store.commit('user/setExpertInfo', A.db.expertInfo)
    A.store.commit('user/setBaseInfo', A.db.baseInfo)
    A.on('localdata-change', key => {
        switch (key) {
            case 'token':
                A.store.commit('user/setToken', A.db.token)
                break;
            case 'baseInfo':
                A.store.commit('user/setBaseInfo', A.db.baseInfo)
                break;
            case 'rx_user':
                A.store.commit('user/setInfo', A.db.user)
                break;
            case 'rx_expertInfo':
                A.store.commit('user/setExpertInfo', A.db.expertInfo)
                break;
        }
    })
    let rxloading = document.querySelector('#rxloading');
    rxloading.parentNode.removeChild(rxloading)
    window.cuAtion = A.catchUaction;
}