<script>
import { mapState } from "vuex";
var appTop = null;
export default {
  el: "#topbar",
  name: "topbar",
  computed: {
    ...mapState("global", {
      border: (state) => state.topBorder,
      page: (state) => state.page,
    }),
    ...mapState("global", {
      hideBar: (state) => state.hideTopBar,
    }),
    ...mapState("user", {
      uinfo: (state) => state.info,
      expertInfo: (state) => state.expertInfo,
    }),
    ...mapState("user", {
      token: (state) => state.token,
    }),
  },
  data: {
    ismore: false,
    msgUnreadCount: '',
  },
  watch: {
    border(val) {
      if (appTop) {
        appTop.className = val ? val : "";
      }
    },
  },
  created() {
    appTop = document.querySelector("#appTop");
    let is404 = /404\.html/.test(location.href);
    if (!A.router.currentRoute.name && !is404) {
      location.href = "/404.html";
    }
    this.getMsg();
  },
  mounted() {
    if (this.page && this.page.name != "ihome") {
      document.querySelector("#appTop .hello").innerHTML =
        "<a href='/'  data-ulog='返回首页'>融象产盟首页</a>";
    }
  },
  methods: {
    logout() {
      A.db.logout();
      setTimeout(() => {
        location.reload();
      }, 100);
    },
    gomsg() {
      if (this.token) {
        A.openWindow("/ucenter/notify", "_blank");
      } else {
        A.openWindow("/user/login", "_blank");
      }
    },
    getMsg() {
      if (A.db.token) {
        A.updata.unreadcount().then(res => {
          if (res.success) {
            if (res.data.msgUnreadCount > 9) {
              this.msgUnreadCount = '9+';
            }else this.msgUnreadCount = res.data.msgUnreadCount;
          }
        });
      }
    },
  },
};
</script>
<style lang="less">
#topbar {
  height: 35px;
  border-bottom: 1px #eeeeee solid;
  box-sizing: border-box;
  color: #666666;
  transition: all 0.6s;
  opacity: 1;
  &.hide {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  .barcon {
    max-width: 1200px;
    margin: auto;
    line-height: 35px;
  }
  .menu {
    padding: 0 13px;
    display: inline-block;
    z-index: 1;
    position: relative;
    cursor: pointer;
    &.user,
    &.hov {
      .line {
        color: #cccccc;
      }
      &:hover {
        background: #eef1f8;
        color: #0f4dff;
      }
    }
  }
  .menu-msg {
    position: absolute;
    top: 0;
    right: -10px;
    border-radius: 10px;
    height: 18px;
    display: inline-block;
    color: #fff;
    background: #f56c6c;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    transform: scale(.7);
    padding: 0 6px;
  }
  .userinfo {
    position: absolute;
    top: 35px;
    left: 0;
    width: 360px;
    background: #ffffff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.08);
    display: none;
    &.showUInfo {
      display: block;
    }
    .uname {
      padding: 24px 30px;
      padding-bottom: 10px;
    }
    .avt {
      padding-right: 20px;
    }
    h3 {
      font-size: 20px;
      color: #111111;
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 5px;
      vertical-align: middle;
      .utag2 {
        margin-left: 5px;
      }
    }
    .ucBtn {
      display: inline-block;
      font-size: 16px;
      color: #2659ed;
      i {
        font-size: 13px;
      }
    }
    .bottom {
      height: 137px;
      background: #f8f9fc;
      padding: 24px 36px;
      a {
        flex: 1;
        display: inline-block;
        text-align: center;
        width: 66px;
        .icon {
          text-align: center;
          padding-top: 12px;
          border-radius: 50%;
          display: inline-block;
          width: 60px;
          height: 60px;
          background: #ebeff7;
          img {
            width: 32px;
            display: inline-block;
          }
          &:hover {
            background: #d3dbec;
          }
        }
        .txt {
          line-height: 3;
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .menudropdown {
    padding: 0;
    display: inline-block;
    z-index: 1;
    position: relative;
    z-index: 111;
    &.hover {
      .menu {
        background: #eef1f8;
        color: #0f4dff;
        .el-icon-arrow-down {
          transform: rotate(180deg);
        }
      }
    }
  }
  .mbapp {
    padding: 22px;
    padding-bottom: 10px;
    width: 330px;
    position: absolute;
    left: 50%;
    top: 33px;
    transform: translateX(-50%);
    background: #ffffff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.08);
    text-align: center;
    .qr {
      padding: 0 14px;
      line-height: 1.5;
      color: #666666;
    }
  }
  .linkmap {
    padding: 20px;
    padding-top: 10px;
    width: 660px;
    position: absolute;
    left: 50%;
    top: 33px;
    transform: translateX(-55%);
    background: #ffffff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.08);
    .line {
      height: 80%;
      width: 1px;
      margin: 20px;
      background-color: #eeeeee;
    }
    .navmenu {
      padding: 0;
      margin: 0;
      min-width: 100px;
      a {
        display: block;
        padding: 0;
        padding-left: 15px;
        line-height: 2.5;
        color: #666666;
        &:hover {
          color: #1c4cdd;
        }
        &.fm {
          color: #2659ed;
        }
      }
    }
  }
}
</style>
